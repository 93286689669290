import React from "react";
import DefaultLayout from "../components/DefaultLayout";
import Hero from "../components/bricks/Hero";
import ThemeBox from "../components/bricks/ThemeBox";
import {themeBright, themeDark} from "../page-styles/landingpage.css";
import ThemedMultiFeature from "../components/bricks/ThemedMultiFeature";
import ThemedSingleFeature from "../components/bricks/ThemedSingleFeature";
import {ThemedButton} from "../components/xui/ThemedButton";

const Features = ({location}) => (
  <DefaultLayout
    title="Features For Game Dev Project Management"
    description="A dedicated project management tool for game developers? Here's the details!"
    location={location}
  >
    <Hero
      size="sm"
      title="Feature overview"
      subline="See how Codecks helps you to stay on top of things."
      bottomImg="barbarian.svg"
    />
    <ThemeBox title="Covering all your needs" theme={themeBright}>
      <ThemedMultiFeature
        features={[
          {
            img: "cards-decks-projects-1.png",
            title: "Cards, Decks & Projects",
            description:
              "Well designed collectible card games provide a great sense of the current state of your game. Let's apply those design cues to project management.",
            button: {
              label: "Learn more",
              url: "/features/cards-decks-and-projects/",
            },
          },
          {
            img: "conversations-notifications-1.png",
            title: "Conversations & Notifications",
            description:
              "Ever lost track of a comment thread? Conversations in Codecks are very explicit about who is part and whether it's been resolved.",
            button: {
              label: "Learn more",
              url: "/features/conversations-and-notifications/",
            },
          },
          {
            img: "hand/whats-next.png",
            title: "Unique Hand Design",
            description:
              "Learn how Codecks' uses the idea of the hand to signal the state of your and your team's progress.",
            button: {
              label: "Learn more",
              url: "/features/unique-hand-design/",
            },
          },
          {
            img: "time-tracking-intro.png",
            title: "Time Tracking",
            description:
              "Optional time tracking allows you and your team to see how much time you're actually spending on your tasks.",
            button: {
              label: "Learn more",
              url: "/features/time-tracking/",
            },
          },
          {
            img: "search-order-1.png",
            title: "Search & Order",
            description:
              "Use our advanced search and ordering options to dice and slice your work just the way you need.",
            button: {
              label: "Learn more",
              url: "/features/search-and-order/",
            },
          },
          {
            img: "hero-cards/always-on-your-side.png",
            title: "Hero Cards and Journeys",
            description:
              "Learn how Hero Cards can bundle your work and how Journeys make recurring bundles easy to work with.",
            button: {
              label: "Learn more",
              url: "/features/hero-cards-and-journeys/",
            },
          },
          {
            img: "metrics-reports-1.png",
            title: "Metrics & Reports",
            description: (
              <>
                <i>"Will we manage to release on time?"</i> is a tough question. Especially in game
                development. Codecks is here to help.
              </>
            ),
            button: {
              label: "Learn more",
              url: "/features/metrics-and-reports/",
            },
          },
          {
            img: "milestones-intro.png",
            title: "Milestones",
            description: "Plan for your release – one milestone at a time.",
            button: {
              label: "Learn more",
              url: "/features/milestones/",
            },
          },
          {
            img: "next/open-development.png",
            title: "Open Development",
            description:
              "Open Decks allow to share part of your project publicly with the your audience.",
            button: {
              label: "Learn more",
              url: "/features/open-development/",
            },
          },
          {
            img: "due-dates/intro.png",
            title: "Due Dates",
            description: "Never forget important dates by setting due dates to your cards",
            button: {
              label: "Learn more",
              url: "/features/due-dates/",
            },
          },
        ]}
      />
    </ThemeBox>
    <ThemeBox title="Integrations" theme={themeDark} withBorder>
      <ThemedSingleFeature
        title="Manage your Discord Community with Decky"
        description="Our Discord bot funnels feedback from your Discord community into plannable and transparent tasks that live right in your project planning. Push your server engagement to the next level."
        img="discord.png"
      >
        <ThemedButton to="/features/discord-integration/">Learn more</ThemedButton>
      </ThemedSingleFeature>
      <ThemedSingleFeature
        title="Need to get your Cards out of Trello?"
        description="Trello's simplicity makes it a common choice when starting out a new project. It lacks in features when it comes to growing projects, however. Our importer makes it straight forward to continue where you've left off."
        img="trello-importer.png"
        imgRight
      >
        <ThemedButton to="/features/vs-trello/#importer">
          Import your tasks from Trello
        </ThemedButton>
      </ThemedSingleFeature>
      <ThemedSingleFeature
        title="Stay up to Date with Slack"
        description="Slack is a great addition to any project due to its powerful real-time communication features. Our two-way integration allows you to keep track of the work that matters."
        img="slack.png"
      >
        <ThemedButton to="/features/slack-integration/">Learn more</ThemedButton>
      </ThemedSingleFeature>
      <ThemedSingleFeature
        title="Control your Tasks with Source Control"
        description="Your commit messages may contain a lot of useful information for your team. Our GitHub, Bitbucket and GitLab integrations allow you to attach your messages directly to the relevant cards."
        img="scm.png"
        imgRight
      >
        <ThemedButton to="/features/source-control-integration/">Learn more</ThemedButton>
      </ThemedSingleFeature>
    </ThemeBox>
    <ThemeBox title="How is Codecks different?" theme={themeBright}>
      <ThemedMultiFeature
        features={[
          {
            img: "vs-trello.png",
            title: "Codecks vs Trello",
            button: {
              label: "Learn more",
              url: "/features/vs-trello/",
            },
          },
          {
            img: "vs-jira.png",
            title: "Codecks vs Jira",
            button: {
              label: "Learn more",
              url: "/features/vs-jira/",
            },
          },
          {
            img: "vs-hacknplan.png",
            title: "Codecks vs HacknPlan",
            button: {
              label: "Learn more",
              url: "/features/vs-hacknplan/",
            },
          },
        ]}
      />
    </ThemeBox>
  </DefaultLayout>
);

export default Features;
